import * as React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';

const NotFoundPage = () => {
  const intl = useIntl();

  return (
    <mains>
      <title>Not found</title>
      <h1>{intl.formatMessage({ id: '404.notfound' })}</h1>
      <p>
        <Link to="/">Go home</Link>.
      </p>
    </mains>
  );
};

export default NotFoundPage;
